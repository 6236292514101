const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

module.exports = function parseDate(date) {
  const newDate = new Date(date);
  return `${
    months[newDate.getUTCMonth()]
  } ${newDate.getUTCDate()}, ${newDate.getUTCFullYear()}`;
};

import React from 'react';
import Container from 'container';
import Headline from 'headline';
import s from './bannerlpa.module.scss';

export default function BannerLPA({ title, subtitle, ...props }) {
  const splittedSubtitle = subtitle.split(' ');
  const subtitle1 = splittedSubtitle.slice(0, 6).join(' ');
  const subtitle2 = splittedSubtitle.slice(6).join(' ');
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <div className={s.titleWrapper}>
          <Headline className={s.title} h1 html={title.normal} />
          <Headline className={s.wild} h1 wild html={title.wild} />
          <span className={s.underline}>G</span>
        </div>
        <div className={s.subtitleWrapper}>
          <Headline
            className={`${s.subtitle} ${s.first}`}
            h1
            secondary
            subtitle
            html={subtitle1}
          />
          <Headline
            className={`${s.subtitle} ${s.second}`}
            h1
            secondary
            subtitle
            html={subtitle2}
          />
        </div>
      </div>
    </Container>
  );
}

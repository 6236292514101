import React from 'react';
import Headline from 'headline';
import s from './double-line.module.scss';

export default function SimpleTitleSubtitle({
  headline,
  subtitle,
  className,
  titleClassName,
  subtitleClassName,
  ...props
}) {
  return (
    <div className={`${s.root} ${className || ''}`}>
      <Headline h1 wild className={`${s.title} ${titleClassName || ''}`} html={headline.headline_line1} />
      <Headline h1 wild className={`${s.title} ${s.second} ${titleClassName || ''}`} html={headline.headline_line2} />
      <div className={s.subtitleWrapper}>
        <Headline h2 subtitle className={`${s.subtitle} ${subtitleClassName || ''}`} html={subtitle.subtitle_line1} />
        <Headline h2 subtitle className={`${s.subtitle} ${subtitleClassName || ''}`} html={subtitle.subtitle_line2} />
      </div>
    </div>
  );
}

const validate = (values) => {
  const errors = {};

  if (values.fullName?.touched) {
    if (!values.fullName.value) {
      errors.fullName = 'Your full name is required';
    }
  }

  if (values.email?.touched) {
    if (!values.email.value) {
      errors.email = 'Your email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email.value)) {
      errors.email = 'Invalid email address';
    }
  }

  if (values.phone?.touched) {
    if (!values.phone.value) {
      errors.phone = 'Phone number required';
    } else if (!/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(values.phone.value)) {
      errors.phone = 'Invalid phone number';
    }
  }

  if (values.msg?.touched) {
    if (!values.msg.value) {
      errors.msg = 'A message is required';
    }
  }

  return errors;
};

export default validate;

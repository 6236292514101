import React from 'react';
import Img from 'img';
import Headline from 'headline';
import Container from 'container';
import getTag from 'utils/get-tag';
import Button from 'button';
import CardE from 'cards/card-e';
import s from './4cardsrow.module.scss';

export default function FourCardsRow({ cards, ...props }) {
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        {cards.map(el => (
          <CardE className={s.card} {...el} />
        ))}
      </div>
    </Container>
  );
}

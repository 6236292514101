import React from 'react';

import s from './template-wrapper.module.scss';

export default function TemplateWrapper({ children, title, ...props }) {
  return (
    <div className={s.root}>
      <div className={s.section}>
        <div className="container">
          <h1 className={s.title}>{title}</h1>
        </div>
      </div>
      {children}
    </div>
  );
}

import React from 'react';
import Container from 'container';
import Button from 'button';
import s from './blog-banner.module.scss';

export default function BlogBanner({ button }) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <Button className={s.button} href={button.href}>
          {button.text}
        </Button>
      </div>
    </Container>
  );
}

import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import Button from 'button';
import SimpleTitleSubtitle from 'shared/section-headlines/simple-title-subtitle';
import FormForBanner from 'shared/form-for-banner';
import s from './form-banner-lp.module.scss';

export default function FormBannerLP({
  title,
  subtitle,
  form_subtitle,
  ...props
}) {
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <SimpleTitleSubtitle
          className={s.titleWrapper}
          titleClassName={s.title}
          subtitleClassName={s.subtitle}
          invert
          {...{ title, subtitle }}
        />
        <div className={s.formContainer}>
          <Headline h2 className={s.formSubtitle}>
            {form_subtitle}
          </Headline>
          <Headline h1 wildExtra className={s.arrow} html="L" />
          <FormForBanner {...props}/>
        </div>
      </div>
    </Container>
  );
}

import React, { useContext } from 'react';
import Head from 'shared/head';
import { context as templatesContext } from 'context/templates-context';
import LpProvider from 'context/lp-context';
import TemplateWrapper from './template-wrapper';

export default function WordpressBody(props) {
  const { keysMode } = useContext(templatesContext) || {};
  const { blog, templates, slug, meta, lp_options, ...otherProps } = props;
  const isTemplates = slug === '/templates';
  const devTemplateProps = (isTemplates && props.acfKeys) || null;
  const WrapperComponent = isTemplates
    ? TemplateWrapper
    : ({ children }) => <>{children}</>;
  const children = templates
    .map(({ acf_fc_layout, component, ...templateProps }, idx) => {
      const {
        acf_fc_layout: templateAcfLayout,
        component: templateComponent,
        ...templateKeysProps
      } = (devTemplateProps && devTemplateProps[idx]) || {};
      try {
        const Component = blog
          ? require(`components/blog-templates/${acf_fc_layout}`).default
          : require(`components/page-templates/${acf_fc_layout}`).default;
        const componentProps = isTemplates && keysMode ? templateKeysProps : templateProps;
        const currentTemplateKeyProps = !!Object.keys(templateKeysProps)[0] && templateKeysProps;
        return (
          <WrapperComponent
            {...devTemplateProps}
            title={acf_fc_layout}
            templateKeyProps={currentTemplateKeyProps}
            key={`${acf_fc_layout}-${idx}-${idx}`}
          >
            <LpProvider value={lp_options}>
              <Component
                {...componentProps}
                templateKeysProps={currentTemplateKeyProps}
                key={`${acf_fc_layout}-${idx}`}
                {...otherProps}
              />
            </LpProvider>
          </WrapperComponent>
        );
      } catch (e) {
        return null;
      }
    })
    .filter(component => component);
  return (
    <>
      <Head {...meta} />
      {children}
    </>
  );
}

import React from 'react';
import Container from 'container';
import Img from 'img';
import Button from 'button';
import Headline from 'headline';
import s from './banner_lp.module.scss';

export default function BannerLpSection({ title, subtitle, button, blue, ...props }) {
  return (
    <Container className={blue ? s.section : `${s.section} ${s.pink}`}>
      <div className={s.wrapper}>
        <Headline className={s.title} h2 tertiary html={title} />
        <Headline className={s.subtitle} h2 tertiary html={subtitle} />
        <Button className={s.button} href={button.href}>{button.text}</Button>
      </div>
    </Container>
  );
}

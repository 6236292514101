import React from 'react';
import Container from 'container';
import ButtonV2 from 'button-v2';
import SimpleTitleSubtitle from 'shared/section-headlines/simple-title-subtitle';
import CardF from 'shared/cards/card-f';
import s from './cards-lp-a.module.scss';

export default function CardsLPA({ title, subtitle, cards, button, ...props }) {
  return (
    <Container className={s.root}>
      <SimpleTitleSubtitle
        titleClassName={s.title}
        subtitleClassName={s.subtitle}
        {...{ title, subtitle }}
      />
      <div className={s.cards}>
        {cards.map(el => (
          <div className={s.cardWrapper}>
            <CardF key={el.headline} {...el} />
          </div>
        ))}
      </div>
      <ButtonV2
        secondary
        wrapperClass={s.buttonWrapper}
        buttonClass={s.button}
        {...{ button, ...props }}
      />
    </Container>
  );
}

import React from 'react';
import Container from 'container';
import TitleSubtitleHeadline from 'headlines/simple-title-subtitle';
import CardD from 'cards/card-d';
import s from './cards-with-popups.module.scss';

export default function CardsWithPopups({
  title,
  subtitle,
  cards,
  ...props
}) {
  return (
    <Container className={s.root}>
      <TitleSubtitleHeadline {...{ title, subtitle }} />
      <div className={s.cards}>
        {cards.map((el, i) => (
          <CardD
            key={el.title}
            index={i}
            hasPopup
            className={s.card}
            {...el}
            {...props}
          />
        ))}
      </div>
    </Container>
  );
}

import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import Icon from 'icon';
import s from './faq_lp.module.scss';

export default function HeroSection({ headline, subtitle, list, ...props }) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <Headline h1 wild secondary center html={headline} />
        <Headline h3 center className={s.subtitle} html={subtitle} />
        <div className={s.content} >
          {list.map(({ title, paragraphs }) => (
            <div className={s.paragraph}>
              <Headline className={s.title} h3 center html={title} />
              {paragraphs.map(({ text }) => (
                <p className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
              ))}
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}

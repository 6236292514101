import React from 'react';
import Container from 'container';
import getTag from 'utils/get-tag';
import s from './banner.module.scss';

export default function TextBanner({ text, ...props }) {
  const TextTag = getTag(text);
  return (
    <Container className={s.root}>
      <TextTag className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
    </Container>
  );
}
